import { INCIDENT_ABORTED, INCIDENT_OPENED } from '../actions';

export default function (state: any = false, action: { type: string; payload: any }) {
  switch (action.type) {
    case INCIDENT_OPENED:
    case INCIDENT_ABORTED:
      const data = action.payload;
      return data;
    default:
      return state;
  }
}
