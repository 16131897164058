import { VESSEL_CLEAR, VESSEL_SELECTED } from '../actions';

export default function (state: any = {}, action: { type: string; payload: any }) {
  switch (action.type) {
    case VESSEL_SELECTED:
    case VESSEL_CLEAR:
      const data = action.payload;
      return { ...state, ...data };

    default:
      return state;
  }
}
