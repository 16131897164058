import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../assets/images/sanAntonio.png';
import openPort from '../../assets/images/openPort.png';

import Hamburger from '../../components/Hamburger/Hamburger.component';
import RealTime from '../../components/RealTime/RealTime.component';

import Cookies from 'universal-cookie';

import styles from './menuBar.module.scss';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, fetchUser, openIncident, openRestriction } from '../../store/actions';
import Modal from 'react-awesome-modal';
import { parseJwt } from '../../utils';

const cookies = new Cookies();

const MenuBar = (props: any) => {
  const [isActive, setIsActive] = useState(cookies.get('token') ? true : false);
  const [visible, setVisible] = useState(false);

  const user = useSelector((state: any) => state.user);

  const dispatch = useDispatch();

  const startIncident = () => dispatch(openIncident());
  const changeState = () => dispatch(openRestriction());

  const openModal = () => setVisible(true);
  const closeModal = () => setVisible(false);

  const handleCookies = () => {
    cookies.remove('token');
    dispatch(deleteUser());
    setIsActive(false);
  };

  type Inputs = {
    username: string;
    password: string;
  };

  const { register, handleSubmit } = useForm<Inputs>();

  const onSubmit = (data: Inputs) => {
    dispatch(fetchUser(data.username, data.password));
    closeModal();
  };

  useEffect(() => {
    if (user.data?.token) {
      cookies.set('token', user.data?.token);
      setIsActive(true);
    }
  }, [dispatch, user]);

  const getToken = () => parseJwt(cookies.get('token'));

  return (
    <Fragment>
      <div className={styles['top-menu']}>
        {getToken()?.role === 'admin' || getToken()?.role === 'captain' ? (
          <>
            <div className={styles['incidence-button']} onClick={() => startIncident()}>
              <span>Reportar incidencia</span>
            </div>
            <div className={styles['status-button']} onClick={() => changeState()}>
              <span>Cambiar estado</span>
            </div>
          </>
        ) : null}
        {/* <RealTime /> */}
        <Hamburger />
        <div
          className={styles['login-button']}
          onClick={() => {
            cookies.get('token') ? handleCookies() : openModal();
          }}>
          <span>{isActive ? 'Logout' : 'Login'}</span>
        </div>
        <a href='/' style={{ textDecoration: 'none' }}>
          <div className={styles['logo']}>
            <img src={logo} alt='San Antonio' style={{ height: '5rem', paddingLeft: '2.5rem', paddingTop: '0.3rem' }} />
          </div>
        </a>
        <div className={styles['title']}>Puerto de San Antonio</div>
      </div>

      <Modal visible={visible} width='370px' effect='fadeInUp' onClickAway={() => closeModal()}>
        <div className='modal'>
          <div className={styles['titleModal']} style={{ whiteSpace: 'nowrap' }}>
            <div id='image' style={{ display: 'inline' }}>
              <img style={{ height: '45px', width: '45px' }} src={openPort} alt='openPort' />
            </div>
            <div id='texts' style={{ display: 'inline', whiteSpace: 'nowrap', fontSize: '50px', paddingLeft: '1rem' }}>
              OpenPort
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div style={{ marginTop: '44px', marginLeft: '22px' }}>
                <label>User:</label>
                <input className={styles['box']} name='username' ref={register} />
              </div>
              <div style={{ marginTop: '22px', marginLeft: '22px' }}>
                <label>Password:</label>
                <input className={styles['box']} name='password' ref={register} type='password' />
              </div>
            </div>

            <button className={styles['submit']} type='submit' style={{ marginLeft: '22px', marginBottom: '22px' }}>
              Login
              <span className={styles['arrow']}>
                <FontAwesomeIcon icon={faChevronRight} />
              </span>
            </button>
          </form>
        </div>
      </Modal>
    </Fragment>
  );
};

export default MenuBar;
