import React, { Fragment, useState } from 'react';
import styles from './hamburger.module.scss';
import { Link } from 'react-router-dom';

const Hamburger = (props: any) => {
  const [open, setOpen] = useState(false);
  const click = () => setOpen(!open);

  return (
    <Fragment>
      <div className={open ? styles['clicked'] : styles['burger']} onClick={click}>
        <span></span>
      </div>

      <nav className={open ? styles['show'] : ''} onClick={click}>
        <ul className={styles['main']}>
          <li>
            <Link to={'./'}>Inicio</Link>
          </li>
          {/* <li>
            <Link to={'./restrictions'}>Restricciones</Link>
          </li> */}
          <li>
            <Link to={'./about'}>Sobre OpenPort</Link>
          </li>
        </ul>
      </nav>

      <div className={open ? styles['show'] : styles['overlay']} onClick={click}></div>
    </Fragment>
  );
};

export default Hamburger;
