import axios from '../../services/axios.service';

export const FETCH_TUGBOATS = 'FETCH_TUGBOATS';
export function fetchTugboats() {
  const request = axios.get(`/v1/tugboats`);
  return {
    type: FETCH_TUGBOATS,
    payload: request,
  };
}
