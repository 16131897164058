import { RESTRICTIONS_ABORTED, RESTRICTIONS_OPENED } from '../actions';

export default function (state: any = {}, action: any) {
  switch (action.type) {
    case RESTRICTIONS_OPENED:
    case RESTRICTIONS_ABORTED:
      const data = action.payload;
      return data;
    default:
      return state;
  }
}
