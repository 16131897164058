import moment from 'moment-timezone';

export const generateHours = () => {
  const current = moment().tz('America/Santiago').hours();
  const hours = [];
  for (let i = current; i < current + 24; i++) {
    if (i <= 23) hours.push(i);
    else hours.push(i - 24);
  }
  return hours;
};

export const parseJwt = (token: any) => {
  if (!token) return null;
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};
