import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './store/reducers';
import promise from 'redux-promise';
import AppRouter from './AppRouter';

import './assets/sass/style.scss';

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, /* preloadedState, */ composeEnhancers(applyMiddleware(promise)));

const App = () => (
  <Provider store={store}>
    <AppRouter />
  </Provider>
);

export default App;
