import { FETCH_FORECAST } from '../actions';

export default function (state: any = {}, action: any) {
  switch (action.type) {
    case FETCH_FORECAST:
      const data = action.payload.data;
      return { ...state, data };

    default:
      return state;
  }
}
