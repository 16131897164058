import axios from '../../services/axios.service';

export const FETCH_BOATS = 'FETCH_BOATS';
export const fetchBoats = () => {
  const request = axios.get('/v1/boats');
  return {
    type: FETCH_BOATS,
    payload: request,
  };
};
