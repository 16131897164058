import { FETCH_SITES } from '../actions';

export default function (state: any = {}, action: { type: string; payload: { data: any } }) {
  switch (action.type) {
    case FETCH_SITES:
      const data = action.payload.data;
      return { ...state, data };

    default:
      return state;
  }
}
