import axios from '../../services/axios.service';

export const FETCH_FORECAST = 'FETCH_FORECAST';
export function fetchForecast() {
  const request = axios.get(`/v1/forecast`);
  return {
    type: FETCH_FORECAST,
    payload: request,
  };
}
