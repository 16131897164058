import React, { Fragment } from 'react';
import styles from './about.module.scss';

import map from '../../assets/images/map.png';

const About = () => {
  return (
    <Fragment>
      <div className={styles['container']}>
        <h1>Clasificación de buques: </h1>
        <p className={styles['paragraph']}>
          Los busques se clasifican por tamaño y tipología según lo indicado en C.P. S.A. ORD. N° 12.000/ 838 / VRS. HABILITA LA OPERACIÓN
          DEL PUERTO DE SAN ANTONIO. De fecha: 12 de Noviembre de 2020.
        </p>{' '}
        <h1>Límites Operacionales: </h1>
        <p>
          Los límites operacionales se han extraído de: C.P. S.A. ORD. N° 12.000/ 838 / VRS. HABILITA LA OPERACIÓN DEL PUERTO DE SAN
          ANTONIO. De fecha: 12 de Noviembre de 2020.
        </p>{' '}
        <h1>Datos de pronóstico de viento y oleaje:</h1>
        <p>
          {' '}
          Se muestran <strong>datos de pronóstico de oleaje</strong> durante las siguientes 24 horas, en diversas ubicaciones:
        </p>
        <div className={styles['list']}>
          <ul>
            <li>Exterior ADCP</li>
            <li>Sitio 1</li>
            <li>Sitio 2</li>
            <li>Sitio 3</li>
            <li>Sitio C1</li>
          </ul>
          <ul>
            <li>Sitio C2</li>
            <li>Sitio 4-5</li>
            <li>Sitio 6-7</li>
            <li>Sitio 8</li>
            <li>Sitio 9</li>
          </ul>
        </div>
        <p>
          La web muestra <strong>datos de pronóstico de viento</strong> durante las siguientes 24 horas, en la ubicación:
        </p>
        <ul>
          <li>Exterior ADCP</li>
        </ul>
        <img src={map} alt='mapa' />
        <p>
          Los datos de <strong>pronóstico de oleaje</strong> son proporcionados por la Dirección de Obras Portuarias (DOP), en colaboración
          con el Instituto Nacional de Hidráulica (INH).
        </p>{' '}
        <p>
          Los datos de <strong>pronóstico de viento</strong> son proporcionados por la Empresa Portuaria San Antonio (EPSA).
        </p>{' '}
        <h1>Características de las naves:</h1>{' '}
        <p>Los datos de características de las naves se obtienen de la aplicación SITPORT, de TECMAR.</p>
        <h1>Restricciones:</h1>{' '}
        <p>
          Los datos que incorpora esta web son informativos. La aplicación de restricciones es competencia exclusiva del Capitán de Puerto.
        </p>
      </div>
    </Fragment>
  );
};

export default About;
