import axios from '../../services/axios.service';

export const VESSEL_SELECTED = 'VESSEL_SELECTED';
export function setVesselSelected(vesselId: string) {
  const request = axios.get(`/v1/vessels/${vesselId}`);
  return {
    type: VESSEL_SELECTED,
    payload: request,
  };
}

export const VESSEL_CLEAR = 'VESSEL_CLEAR';
export function setVesselClear() {
  return {
    type: VESSEL_CLEAR,
    payload: {},
  };
}

/**
 * { data: {
      image:"https://d1j1hju86akilu.cloudfront.net/AcuCustom/Sitename/DAM/029/MSC-Unveils-Suspension-on-Transit-Programme.png",
      name: 'CAROLINE MAERSK - 9214903',
      classification: 'Grandes',
      loa: 228.80,
      manga: 32,
      calado: 10.01,
      type: 'Container Ship',
      eta: '01:34, 11-12-2020',
      amarre: '06:00, 11-12-2020',
      desamarre: '05:00, 13-12-2020',
      site: 'Sitio 4/5'
    } }
 */
