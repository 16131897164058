import axios from '../../services/axios.service';

export const SET_PARAMS = 'SET_PARAMS';
export const setParams = (params: any) => {
  const payload = params;
  return {
    type: SET_PARAMS,
    payload: payload,
  };
};

export const DELETE_PARAMS = 'DELETE_PARAMS';
export const deleteParams = () => {
  return {
    type: DELETE_PARAMS,
    payload: {},
  };
};

export const POST_PARAMS = 'POST_PARAMS';
export const postParams = (params: any) => {
  const request = axios.post('/v1/restrictions', params);
  return {
    type: POST_PARAMS,
    payload: request,
  };
};
