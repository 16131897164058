export const INCIDENT_OPENED = 'INCIDENT_OPENED';
export function openIncident() {
  return {
    type: INCIDENT_OPENED,
    payload: true,
  };
}

export const INCIDENT_ABORTED = 'INCIDENT_ABORTED';
export function abortIncident() {
  return {
    type: INCIDENT_ABORTED,
    payload: false,
  };
}
