import axios from '../../services/axios.service';

export const FETCH_SITES = 'FETCH_SITES';
export function fetchSites() {
  const request = axios.get(`/v1/sites`);
  return {
    type: FETCH_SITES,
    payload: request,
  };
}
