import { SET_PARAMS, DELETE_PARAMS } from '../actions';

export default (state: any = {}, action: any) => {
  switch (action.type) {
    case DELETE_PARAMS:
    case SET_PARAMS:
      const data = action.payload;
      return { ...state, data };

    default:
      return state;
  }
};
