import moment from 'moment';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRestrictions } from '../../store/actions';
import styles from './historicalRestrictions.module.scss';

moment.locale('es');

const HistoricalRestrictions = () => {
  const restrictions: any = useSelector((state: any) => state.listRestrictions);
  const dispatch = useDispatch();

  const transformSeconds = (sec: any) => {
    let seconds = parseInt(sec, 10);
    const days = Math.floor(seconds / (3600 * 24));
    seconds -= days * 3600 * 24;
    const hrs = Math.floor(seconds / 3600);
    seconds -= hrs * 3600;
    const mnts = Math.floor(seconds / 60);
    seconds -= mnts * 60;

    const displayDays = days > 0 ? `${days} días, ` : '';
    const displayHours = hrs > 0 ? `${hrs} horas, ` : '';
    const displayMinutes = mnts > 0 ? `${mnts} minutos, ` : '';
    const displaySeconds = seconds > 0 ? `${seconds} segundos` : '';

    return displayDays + displayHours + displayMinutes + displaySeconds;
  };

  useEffect(() => {
    dispatch(fetchRestrictions());
  }, [dispatch]);

  return (
    <Fragment>
      <h1>Restricciones</h1>
      <table>
        <tr>
          <th>Sitio</th>
          <th>Estado</th>
          <th>Inicio</th>
          <th>Duración</th>
        </tr>
        {restrictions?.data?.length > 0
          ? restrictions.data.map((restriction: any) => (
              <tr>
                <td>{restriction.site.name}</td>
                <td className={styles['status']}>{restriction.newStatus}</td>
                <td>{moment(restriction.createdAt).calendar()}</td>
                <td>{restriction.duration ? transformSeconds(restriction.duration) : null}</td>
              </tr>
            ))
          : null}
      </table>
    </Fragment>
  );
};

export default HistoricalRestrictions;
