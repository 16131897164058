import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations from './assets/i18n';

const resources = translations;

export const getLanguage = () => {
  const availableLanguages = ['en', 'es'];
  const currentLanguage = i18n.language || (typeof window !== 'undefined' && window.localStorage.i18nextLng) || 'en';

  return availableLanguages.includes(currentLanguage) ? currentLanguage : 'en';
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getLanguage(),
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
export default i18n;
