import axios from '../../services/axios.service';

export const FETCH_USER = 'FETCH_USER';
export function fetchUser(username: string, password: string) {
  const request = axios.post(`/v1/users/login`, { username, password });
  return {
    type: FETCH_USER,
    payload: request,
  };
}

export const DELETE_USER = 'DELETE_USER';
export function deleteUser() {
  return {
    type: DELETE_USER,
    payload: null,
  };
}
