import { DELETE_USER, FETCH_USER } from '../actions';

export default function (state: any = {}, action: { type: string; payload: { data: any } }) {
  switch (action.type) {
    case FETCH_USER:
      const data = action.payload.data;
      return { ...state, data };

    case DELETE_USER:
      return { ...state, ...{ data: action.payload } };

    default:
      return state;
  }
}
