import { request } from 'https';
import axios from '../../services/axios.service';

export const RESTRICTIONS_OPENED = 'RESTRICTIONS_OPENED';
export function openRestriction() {
  return {
    type: RESTRICTIONS_OPENED,
    payload: true,
  };
}

export const RESTRICTIONS_ABORTED = 'RESTRICTIONS_ABORTED';
export function abortRestriction() {
  return {
    type: RESTRICTIONS_ABORTED,
    payload: false,
  };
}

export const FETCH_RESTRICTIONS = 'FETCH_RESTRICTIONS';
export function fetchRestrictions() {
  const request = axios.get('/v1/restrictions');
  return {
    type: FETCH_RESTRICTIONS,
    payload: request,
  };
}
