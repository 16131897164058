import axios from '../../services/axios.service';

export const FETCH_VESSELTYPES = 'FETCH_VESSELTYPES';
export const fetchVesselTypes = () => {
  const request = axios.get(`/v1/vesselTypes`);
  return {
    type: FETCH_VESSELTYPES,
    payload: request,
  };
};
