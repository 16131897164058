import { SET_WIZARD_STEP } from '../actions';

export default function (state: any = 1, action: { type: string; payload: number }) {
  switch (action.type) {
    case SET_WIZARD_STEP:
      const data = action.payload;
      return data;
    default:
      return state;
  }
}
