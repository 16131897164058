export const constants = {
  // url: 'http://localhost:9000',
  url: 'https://apidop.pelcam.io',
  openweather: {
    url: 'https://api.openweathermap.org/data/2.5/weather',
    apikey: '16abf0c91c411985f8eb1cf2f5fa6dd7',
    icons: 'http://openweathermap.org/img/wn/',
  },
};

export const classificationTypes = {
  supper: {
    color: '#0e8cb3',
    short: 'LOA > 253m o manga > 32.2m',
    description: 'L.O.A. > 253 m o manga > 32.2 m',
  },
  big: {
    color: '#22c5a0',
    short: 'LOA < 253m y manga < 32.2m',
    description: 'L.O.A. <= 253 m y manga <= 32.2 m',
  },
  roro: {
    color: '#eb7045',
    short: 'Ro - Ro',
    description: '',
  },
  passengers: {
    color: '#bd10e0',
    short: 'Pasajeros',
    description: '',
  },
  small: {
    color: '#a8a201',
    short: 'Menores',
    description: '< 500 TRG',
  },
};
