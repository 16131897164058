import { combineReducers } from 'redux';
import SiteReducer from './site.reducer';
import VesselReducer from './vessel.reducer';
import IncidentReducer from './incident.reducer';
import WizardReducer from './wizard.reducer';
import ForecastReducer from './forecast.reducer';
import TugboatReducer from './tugboat.reducer';
import BoatReducer from './boat.reducer';
import VesselTypesReducer from './vesselType.reducer';
import CloseReducer from './close.reducer';
import RestrictionReducer from './restriction.reducer';
import UserReducer from './user.reducer';
import ListRestricions from './listRestrictions.reducer';

const rootReducer = combineReducers({
  selectedVessel: VesselReducer,
  vesselTypes: VesselTypesReducer,
  sites: SiteReducer,
  incidentStatus: IncidentReducer,
  wizardStep: WizardReducer,
  forecast: ForecastReducer,
  tugboats: TugboatReducer,
  boats: BoatReducer,
  closeForm: CloseReducer,
  restrictionStatus: RestrictionReducer,
  listRestrictions: ListRestricions,
  user: UserReducer,
});

export default rootReducer;
