import { FETCH_BOATS } from '../actions';

export default (state: any = {}, action: any) => {
  switch (action.type) {
    case FETCH_BOATS:
      const data = action.payload.data;
      return { ...state, data };

    default:
      return state;
  }
};
