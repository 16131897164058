import { FETCH_RESTRICTIONS } from '../actions';

export default (state: any = {}, action: any) => {
  switch (action.type) {
    case FETCH_RESTRICTIONS:
      const data = action.payload.data;
      return { ...state, data };
    default:
      return state;
  }
};
