import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import About from './containers/About/About.container';
import HistoricalRestrictions from './containers/HistoricalRestrictions/HistoricalRestrictions.container';
import MenuBar from './containers/MenuBar/MenuBar.container';

const Home = lazy(() => import('./containers/Home/Home.container'));

const AppRouter = () => (
  <BrowserRouter>
    <Suspense fallback={<></>}>
      <MenuBar />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/about' exact component={About} />
        <Route path='/restrictions' exact component={HistoricalRestrictions} />
      </Switch>
    </Suspense>
  </BrowserRouter>
);

export default AppRouter;
